/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
    min-height: calc(100vh - 64px - 40px - 20px);
    max-height: calc(100vh - 64px - 40px - 20px);
    overflow: auto;
}

.mobile-landscape-container {
    min-height: calc(100vh - 64px - 40px - 20px);
    max-height: calc(100vh - 40px);
    overflow: auto;
}