/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
    input {
        min-width: 280px;
        max-width: calc(100vh - 52px - 12px);
    }
}