/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
    min-height: 336px;
    & > {
        .overflow-auto {
            &::-webkit-scrollbar-thumb {
                background: rgba(232, 186, 115, 1);
                border-radius: 1000px;
            }
            &::-webkit-scrollbar-track:not(#map-content) {
                border-radius: 1000px;
            }
            &::-webkit-scrollbar {
                background: rgba(33, 35, 36, 1);
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 1000px;
            }
        }
    }
}