/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.progressbar-trail {
  strokeWidth: 3px;
}

.progressbar-path {
  strokeWidth: 6px;
  box-shadow: inset 4px 8px white;
}

.progressbar-text {
  //styleName: Body/regular;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}