$prefixClass: rc-slider;
$height: 6px;

.#{$prefixClass} {
  &-rail {
    background: rgba(38, 41, 45, 1);
    height: $height;
  }

  &-track {
    background: rgba(232, 186, 115, 1);
    height: $height;
  }

  &-handle {
    background: rgba(255, 255, 255, 1);
    width: 10px;
    height: 10px;
    border: none;
    opacity: 1;
    margin-top: -2px;

    &-dragging {
      box-shadow: none !important;
    }
  }
}