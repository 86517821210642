/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
$game_pos_top: (
  10%,
  10%,
  10%,
  35%,
  65%,
  90%,
  90%,
  90%,
  65%,
  35%
);
$game_pos_left: (
  30%,
  50%,
  70%,
  100%,
  100%,
  70%,
  50%,
  30%,
  0%,
  0%
);
$game_pos_transform: (
  (-50%, -50%),
  (-50%, -50%),
  (-50%, -50%),
  (-100%, -50%),
  (-100%, -50%),
  (-50%, -50%),
  (-50%, -50%),
  (-50%, -50%),
  (0%, -50%),
  (0%, -50%),
);
$game_pos_padding_top: (
  0px,
  0px,
  0px,
  0px,
  0px,
  36px,
  36px,
  36px,
  0px,
  0px
);

.container {

  // border: 1px solid white;
  // scale: 0.5;
  .player-container {

    // padding-left: 8px;
    // padding-right: 8px;
    .board-container {
      position: relative;

      // border: 1px white solid;
      // width: 768px;
      // max-width: 100%;
      // height: 450px;
      .board-background {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // max-width: 80%;
      }

      @for $i from 1 to 11 {
        .gameP#{$i} {
          top: nth($list: $game_pos_top, $n: $i);
          left: nth($list: $game_pos_left, $n: $i);
          transform: translate(nth($list: $game_pos_transform, $n: $i));
        }
      }
    }

    .controller-container {
      // width: 100%;
      // display: flex;
      // justify-content: center;
    }
  }

  .answer-panel {
    // border: 1px solid white;
    // height: 100%;
  }
}