$chip_pos_top: (
    115%,
    115%,
    115%,
    45%,
    45%,
    -45%,
    -45%,
    -45%,
    20%,
    20%
);
$chip_pos_left: (
    50%,
    50%,
    50%,
    -15%,
    -15%,
    50%,
    50%,
    50%,
    115%,
    115%
);
$chip_pos_margin_top: (
    10px,
    10px,
    10px,
    0,
    0,
    -10px,
    -10px,
    -10px,
    0,
    0
);
$chip_pos_margin_top_mobile: (
    4px,
    4px,
    4px,
    0,
    0,
    -4px,
    -4px,
    -4px,
    0,
    0
);
$bb_pos_top: (
    100%,
    100%,
    100%,
    100%,
    100%,
    -25%,
    -25%,
    -25%,
    100%,
    100%
);
$bb_pos_left: (
    0px,
    0px,
    0px,
    0px,
    0px,
    0px,
    0px,
    0px,
    0px,
    0px
);

.player-wrapper {
    @for $i from 1 to 11 {
        .chipPCommon#{$i} {
            top: nth($chip_pos_top, $i);
            left: nth($chip_pos_left, $i);
            transform: translateX(-50%);
        }

        .chipP#{$i} {
            @extend .chipPCommon#{$i};
            margin-top: nth($list: $chip_pos_margin_top, $n: $i);
        }

        .chipP#{$i}Mobile {
            @extend .chipPCommon#{$i};
            margin-top: nth($list: $chip_pos_margin_top_mobile, $n: $i);
        }

        .gameChipBBWrapper#{$i} {
            top: nth($bb_pos_top, $i);
            left: nth($bb_pos_left, $i);
            width: 100%;
        }
    }
}

.pokerPlayerMPWrapperInverted {
    background-color: rgba(227, 227, 227, 0.95);
}

.pokerPlayerMPWrapper {
    background-color: rgba(50, 50, 50, 0.95);
}