/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  background: linear-gradient(0deg, rgba(21, 23, 26, 0.5), rgba(21, 23, 26, 0.5)),
    radial-gradient(99.31% 94.34% at 49.88% -2.58%, rgba(213, 183, 99, 0.23) 0%, rgba(213, 183, 99, 0) 72.18%),
    linear-gradient(89.87deg, rgba(232, 186, 115, 0.1) 0%, rgba(232, 186, 115, 0.01) 100%);
}