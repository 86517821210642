$md: 768px;
$lg: 1024px;
$xl: 1200px;

.tooltip {
  position: relative;
  border-bottom: 1px dashed #000;
  cursor: pointer;

  &:before {
    z-index: 10000;
    content: attr(data-text);
    /* here's the magic */
    position: absolute;
    /* vertically center */
    top: 0%;
    transform: translateY(-100%);
    /* move to right */
    // left: 100%;
    // margin-left: 15px;
    /* basic styles */

    @media screen and (max-width: $md) {
      width: calc(250px - 3rem);
    }

    @media screen and (min-width: $md) and (max-width: $lg) {
      width: calc(250px - 3rem);
    }

    @media screen and (min-width: $lg) and (max-width: $xl) {
      width: calc(280px - 3rem);
    }

    @media screen and (min-width: $xl) {
      width: calc(280px - 3rem);
    }

    padding: 10px;
    border-radius: 10px;
    background: #000;
    color: #fff;
    text-align: center;
    // display: block;
    display: none;
    /* hide by default */
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}